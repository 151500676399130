<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="4">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">Capturar Datos</div>
          </template>
          <v-text-field
            v-model="matricula"
            :counter="15"
            label="Número de Empleado o Matrícula"
            required
            :disabled="editing"
          ></v-text-field>
          <v-text-field
            v-model="nombre"
            :counter="100"
            label="Nombre(s)"
            :disabled="editing"
            required
            @input="nombre = nombre.toUpperCase()"
          ></v-text-field>
          <v-text-field
            v-model="apellidos"
            :counter="100"
            label="Apellidos"
            :disabled="editing"
            required
            @input="apellidos = apellidos.toUpperCase()"
          ></v-text-field>
          <v-text-field v-model="correo" :counter="50" label="Correo" :disabled="editing" required  @input="correo = correo.toLowerCase()"></v-text-field>
          <v-text-field
            v-model="carrera"
            :counter="50"
            label="Carrera"
            :disabled="editing"
            required
          ></v-text-field>
          <v-select v-model="tipo" label="Tipo de Usuario" :items="tipos"></v-select>
          <v-select
            v-model="tutor"
            label="Tutor"
            :items="tutores"
            item-text="correo"
            item-value="idTutor"
          ></v-select>
          <v-btn
            small
            color="verdeBoton"
            :disabled="editing"
            @click="dialog = true"
          >Registrar Cuenta</v-btn>
          <v-btn small color="verdeBoton" @click="limpiarCampos">Limpiar Campos</v-btn>
        </base-material-card>
      </v-col>

      <v-col :cols="8">
        <base-material-card color="drawerColorSecondary">
          <template v-slot:heading>
            <div class="display-2 font-weight-medium">Cuentas</div>
          </template>
          <v-spacer></v-spacer>
          <v-col cols="4" class="text-right">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line></v-text-field>
          </v-col>

          <v-responsive class="overflow-y-auto" max-height="calc(90vh - 350px)">
            <v-data-table
              :headers="headers"
              :items="cuentas"
              :search="search"
              :items-per-page="5"
              item-key="idCuenta"
              dense
              no-data-text="No se Encuentran Cuentas"
            >
              <template v-slot:item.accion="{ item }">
                <v-icon medium color="primary" @click="editItem(item)">mdi-pencil</v-icon>
                <!-- <v-icon medium color="primary" @click="deleteItem(item)">mdi-delete</v-icon> -->
              </template>
            </v-data-table>
          </v-responsive>
        </base-material-card>

        <!-- <v-col cols="12" class="text-right">

          <v-dialog v-model="statusForm" persistent max-width="400">
            <v-card>
              <v-card-title>
                <h2>Cambiar tipo de Usuario</h2>
              </v-card-title>
              <v-card-text>
                <v-form class="px-3">
                  <v-select v-model="newTipo" :items="this.tipos" label="Tipo de Usuario"></v-select>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="statusForm = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="guardarCambios()">Aceptar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EditarCuentas",
  data: () => ({
    search: "",
    headers: [
      { text: "Matrícula", value: "idCuenta" },
      { text: "Nombre(s)", value: "nombre" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Correo Electrónico", value: "correo" },
      { text: "Tipo de Usuario", value: "tipo" },
      //{ text: "Carrera", value: "carrera" },
      //{ text: "Tutor", valule: "tutor" }
      { text: "Acciones", value: "accion", sortable: false, width: "8%" },
    ],
    tipos: ["Administrador", "Alumno", "Maestro", "Responsable"],
    tipo: "",
    tutores: [],
    matricula: "",
    nombre: "",
    apellidos: "",
    correo: "",
    tutor: "",
    carrera: "",
    tipo: "",
    cuentas: [],
    val: "",
    onSelect: false,
    statusForm: false,
    editing: false,
  }),
  mounted() {
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/soloCuentas", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((cuentas) => {
        this.cuentas = cuentas;
      });

    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/tutores", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.$store.state.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((tutores) => {
        this.tutores = tutores;
      });
  },
  methods: {
    handleSelection: function (val) {
      //console.log(val);
    },
    guardarCambios() {
      this.selected.forEach((cuenta) => {
        //console.log(cuenta);
        fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/cuentas", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.$store.state.cuenta.token,
          },
          body: JSON.stringify({
            idCuenta: cuenta.idCuenta,
            nombre: cuenta.nombre,
            apellidos: cuenta.apellidos,
            correo: cuenta.correo,
            contrasena: cuenta.contrasena,
            tipo: this.newTipo,
            estado: cuenta.estado,
            idTutor: cuenta.idTutor,
            claveCarrera: cuenta.carrera,
          }),
        }).then(() => {
          let posicion = this.cuentas.indexOf(cuenta);
          this.cuentas.splice(posicion, 1);
          this.cuentas.push(cuenta);
        });
      });
      this.selected = [];
      this.statusForm = !this.statusForm;
      this.onSelect = false;
    },
    onScroll(e) {
      this.offsetTop = e.target.scrollTop;
    },
    editItem(item) {
      this.editing = !this.editing;
      //console.log(item);
      this.matricula = item.idCuenta;
      this.nombre = item.nombre;
      this.apellidos = item.apellidos;
      this.correo = item.correo;
      this.carrera = item.carrera;
      this.tipo = item.tipo;
      this.tutor = item.idTutor;
    },
    deleteItem(item) {
      //console.log(item);
    },
    limpiarCampos() {
      this.matricula = "";
      this.nombre = "";
      this.apellidos = "";
      this.correo = "";
      this.tutor = "";
      this.carrera = "";
      this.tipo = "";
      this.editing = !this.editing;
    },
  },
};
</script>
